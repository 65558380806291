import React from "react"
import Layout from "../components/layout";
import "../styles/index.scss";

export default function Home() {
  return (
    <Layout>
      <h1>Wir entwickeln individuelle Software</h1>
      <p>
        <strong>guler.digital</strong> UG (haftungsbeschränkt) ist eine Webentwicklungsagentur, die sich auf die
        Entwicklung kundenspezifischer, skalierbarer Lösungen mit Java, .NET Core, Node.js, Angular, React, DBMS, SQL
        und verwandten Technologien spezialisiert hat. Wir arbeiten Seite an Seite mit unseren Kunden, um innovative
        Lösungen zu entwerfen, zu entwickeln und zu implementieren, die ihren individuellen Bedürfnissen entsprechen.
        <br/><br/>
        Wir beginnen jedes Projekt mit einer Überprüfung der bestehenden Betriebsabläufe und einer Auswertung der
        aktuellen und zukünftigen Anforderungen unserer Kunden. Anschließend setzen wir diese Anforderungen in konkrete
        strategische und technische Lösungen um.
      </p>
      <br/>
      <div className="expertise">
        <div className="column">
          <strong>Strategie</strong>
          <ul>
            <li>Beratung</li>
            <li>Anforderungsanalyse & Design</li>
            <li>Iterative und agile Entwicklung</li>
          </ul>
        </div>
        <div className="column">
          <strong>Technologie</strong>
          <ul>
            <li>Front- & Backend Web Applikationen</li>
            <li>Hybrid-Mobile Applikationen</li>
            <li>Performance & Accessibility</li>
            <li>GitLab, GitHub, Jenkins</li>
            <li>Docker, Kubernetes</li>
            <li>AWS, Azure</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
